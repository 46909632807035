import { Modal } from "baseui/modal";
import React, { useContext, useEffect, useState } from "react";
import Plus from "../../assets/img/svg/Upload/Plus";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../../components/SelectBox";
import { useMutation } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import getSelectValues from "../../utils/getSelectValues";
import { useIntl } from "react-intl";
import { ParagraphLarge } from "baseui/typography";
import { addUser, getUsers } from "./indexedDBUtil";

const AddReportModal = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const [users, setUsers] = useState({});

  const { referenceData } = useContext(MaxyfiContext);

  function close() {
    setIsOpen(false);
  }

  //   const putCustomerStatusService = useMutation(
  //     (data) =>
  //       service({
  //         organization: currentOrganization,
  //         customerId: getParams,
  //         ...data,
  //       }),
  //     {
  //       onError: (error, variables, context) => {
  //         if (error.response) {
  //           let { data } = error.response;
  //           let errorData = data.message;
  //           toast.error(errorData);
  //         }
  //       },
  //       onSuccess: (data, variables, context) => {},
  //     }
  //   );

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    let value = {
      ...data,
      rep: getSelectValues(data.rep),
      cli: getSelectValues(data.cli),
      frq: getSelectValues(data.cli),
    };
    // await addUser(value, "reportData");

    // await putCustomerStatusService.mutateAsync(values);
  };

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const users = await getUsers();
  //     setUsers(users);
  //   };

  //   fetchUsers();
  // }, []);

  return (
    <>
      <TextButton
        size="mini"
        startEnhancer={() => <Plus size={18} />}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add Report
      </TextButton>

      <Modal
        onClose={close}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "22px 22px",
              width: "auto",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form
          style={{
            textAlign: "center",
            marginTop: "15px",
            padding: "10px 20px",
            width: "700px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ParagraphLarge $style={{ marginBottom: "20px" }}>
            Add Report
          </ParagraphLarge>
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "200px" }}>
              <Controller
                name="rep"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "select_report",
                    })}
                    placeholder={intl.formatMessage({
                      id: "select_report",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["custom_reports"].sort((a, b) =>
                      a?.id.localeCompare(b?.id)
                    )}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="cli"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "select_client",
                    })}
                    placeholder={intl.formatMessage({
                      id: "select_client",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["customer_status_list"].sort(
                      (a, b) => a?.id.localeCompare(b?.id)
                    )}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="frq"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "frequency",
                    })}
                    placeholder={intl.formatMessage({
                      id: "frequency",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["customer_status_list"].sort(
                      (a, b) => a?.id.localeCompare(b?.id)
                    )}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="time"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "start_time",
                    })}
                    placeholder={intl.formatMessage({
                      id: "start_time",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["customer_status_list"].sort(
                      (a, b) => a?.id.localeCompare(b?.id)
                    )}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="st_dt"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "next_excution_date",
                    })}
                    placeholder={intl.formatMessage({
                      id: "next_excution_date",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["customer_status_list"].sort(
                      (a, b) => a?.id.localeCompare(b?.id)
                    )}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Controller
                name="typ"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    // creatable={creatablePermission ? true : false}
                    size="mini"
                    clearable={false}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["customer_status_list"].sort(
                      (a, b) => a?.id.localeCompare(b?.id)
                    )}
                  />
                )}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: "15px",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              padding: "10px 20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="button"
                fullWidth
                size={SIZE.mini}
                kind={KIND.tertiary}
                // onClick={close}
                onClick={() => {
                  reset();
                  close();
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="submit"
                fullWidth
                size={SIZE.mini}
                kind={KIND.primary}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </TextButton>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddReportModal;
