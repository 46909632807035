import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST_BS = process.env.REACT_APP_HOST;
const HOST = process.env.REACT_APP_HOST_TEMPLATE;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const createReportService = async ({
  organization = null,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/report-maintenance`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const copyReportService = async ({
  organization = null,
  copyId,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/report-maintenance/${copyId}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updateReportService = async ({
  organization = null,
  reportId,
  ...templateData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/report-maintenance/${reportId}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

//Download setting ṭhe report

export const updateReportDownloadService = async ({
  organization = null,
  reportId,
  ...templateData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/report-maintenance/${reportId}/download-setting`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
