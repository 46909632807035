import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/img/logo.png";
import {
  ACTIVITY_REPORT,
  ADHOC_UPLOAD,
  ADJUSTMENT_REPORT,
  AGING_SUMMARY_REPORT,
  ALL_REPORTS_DOWNLOAD_ACCESS,
  BANKRUPTCY_MAINTENANCE,
  CHECKIN_REPORT,
  CHECK_MAINTENANCE,
  CLIENT_DASHBOARD,
  CLIENT_PORTAL_ACCESS,
  COMMAND_CENTER_DASHBOARD,
  COMMUNICATION_TEMPLATE_MAINTENANCE,
  COMPLIANCE_BREACH_REPORT,
  COM_UN_SUBSCRIBE_REPORT,
  CREDIT_LIMIT_REPORT,
  CUSTOMERANALYSIS,
  CUSTOMER_OVERVIEW,
  Contact_Status_Maintenance,
  DASHBOARD,
  DEBT_COLLECTION_DASHBOARD,
  DISPUTE_REPORT,
  EMAIL_ANALYTICS,
  HOLIDAY_BLACKLIST_MAINTENANCE,
  INVOICE_ITEM_MAINTENANCE,
  INVOICE_OVERVIEW,
  KPI_MENU_CODE,
  LEGAL_DEBT_REPORT,
  LEGAL_MOTION_REPORT,
  LEGAL_SUMMARY_REPORT,
  LEGAL_TIMESHEET_REPORT,
  LETTER_STATUS,
  LOCATION_TRACKER,
  MINI_MIRANDA_CONFIGURATION,
  NEEDS_ACTION_REPORT,
  NOTES_REPORT,
  ORGANIZATION_SETTINGS,
  PAYMENT_PLAN_INSTALMENT,
  PAYMENT_PLAN_REPORT,
  PAYMENT_REPORT,
  PERFORMANCE_DASHBOARD,
  PROMISE_REPORT,
  RECURRING_REPORT,
  REPORTS,
  REQUEST_INFORMATION,
  REVENUE_BILLING,
  REVENUE_DASHBOARD,
  REVIEW_GROUP_REPORT,
  ROLE_MAINTENANCE,
  SKIP_TRACE_REPORT,
  SMS_ANALYTICS,
  STICKY_MAINTENANCE,
  STRATEGY_MAINTAINANCE,
  UPLOAD_CLIENT_CUSTOMER_INVOICE,
  USER_DASHBOARD,
  USER_MAINTENANCE,
  VIEW_CONSUMER_STATUS,
  WORKFLOW_MAINTENANCE,
  BUSINESS_UNIT_MAINTENANCE,
  GLOBAL_SEARCH_PERMISSION,
  CREDIT_NOTE,
  REPORT_MAINTAINANCE_ALL,
} from "../../providers/RBACProvider/permissionList";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import OrgSwitchSync from "../../components/OrgSwitchSync/OrgSwitchSync";
import SearchIcon from "../../components/svg/SearchIcon";
import { Icon, Typography } from "../../components_v2";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { useNavigate, useParams, useLocation } from "react-router";
import OrgSwitch, { OrgList } from "../../components/OrgSwitchSync/OrgSwitch";
import GlobalSearch_V2 from "../../components/GlobalSearch/GlobalSearch_V2";
import NotificationDrawer_V2 from "../../components/Notifications/NotificationDrawer_V2";
import { ModalContext } from "../../providers/ModalProvider";
import { useKeycloak } from "@react-keycloak/web";
import warningImage from "../../assets/img/warning.png";
import Logout from "../../assets/img/svg/Logout";
import getUserManualPath from "../../utils/getUserManualPath";
import { ContactModal } from "../SideBar/SideBar";
import { useSearchParams } from "react-router-dom";
import { notificationStatus } from "../../services/users";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { permission } from "../../providers/RBACProvider";
import { resetIndexedDB } from "../../views/ReportDeliverySettings/indexedDBUtil";

const SideBar_V2 = () => {
  let location = useLocation();
  const navigator = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSideBarAvtive, setIsSideBarActive] = useState(false);
  const divRef = useRef(null); // Ref for the div element

  console.log("isSideBarAvtive", isSideBarAvtive);

  const { keycloak } = useKeycloak();
  const HELP_CENTER = process.env.REACT_APP_USER_MANUAL;

  const { customerId } = useParams();

  const { handleModal } = useContext(ModalContext);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const {
    space,
    currentDefaultFormatDetails,
    currentOrganization,
    referenceData,
    appAccessRest,
  } = maxyfiContextValue;

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("q");

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleClick = () => {
    setIsClicked(true);
  };

  const handleMouseMove = () => {
    if (isClicked) {
      setIsClicked(false);
    }
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      handleMouseEnter("");
      setIsSideBarActive(false); // Change state to false if clicked outside
    }
  };

  useEffect(() => {
    if (getParams) {
      // setGlobalFilter();
      setIsSideBarActive(true);
      handleMouseEnter("SEARCH");
    }
    // return () => setIsOpens(false);
  }, [getParams]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const revenueBilling =
    space && space?.role_permissions?.includes(REVENUE_BILLING);
  const businessUnitMaintenance =
    space && space?.role_permissions?.includes(BUSINESS_UNIT_MAINTENANCE);
  const roleMaintenancePermission =
    space && space?.role_permissions?.includes(ROLE_MAINTENANCE);

  const workflowMaintenancePermission =
    space && space?.role_permissions?.includes(WORKFLOW_MAINTENANCE);

  const communicationTemplateMaintenance =
    space &&
    space?.role_permissions?.includes(COMMUNICATION_TEMPLATE_MAINTENANCE);
  const organizationSettings =
    space && space?.role_permissions?.includes(ORGANIZATION_SETTINGS);
  const userMaintenancePermission =
    space && space?.role_permissions?.includes(USER_MAINTENANCE);
  const holidayBlackListMaintenance =
    space && space?.role_permissions?.includes(HOLIDAY_BLACKLIST_MAINTENANCE);

  const miniMiranda =
    space && space.role_permissions.includes(MINI_MIRANDA_CONFIGURATION);
  const consumerStatusManintenance =
    space && space.role_permissions.includes(VIEW_CONSUMER_STATUS);
  const kpiManitenance =
    space && space.role_permissions.includes(KPI_MENU_CODE);
  const bankruptcyMaitenance =
    space && space.role_permissions.includes(BANKRUPTCY_MAINTENANCE);
  const strategicTemplateMaintenance =
    space && space?.role_permissions?.includes(STRATEGY_MAINTAINANCE);

  // misc
  const requestInformation =
    space && space.role_permissions.includes(REQUEST_INFORMATION);
  const invoiceItemMaintenance =
    space && space?.role_permissions?.includes(INVOICE_ITEM_MAINTENANCE);
  const letterStatus =
    space && space?.role_permissions?.includes(LETTER_STATUS);
  const contactStatusMain =
    space && space?.role_permissions?.includes(Contact_Status_Maintenance);
  const locationTracker =
    space && space.role_permissions.includes(LOCATION_TRACKER);

  // client login
  const isClientLogin =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  let settingArray = [
    businessUnitMaintenance,
    revenueBilling,
    communicationTemplateMaintenance,
    workflowMaintenancePermission,
    organizationSettings,
    roleMaintenancePermission,
    userMaintenancePermission,
    holidayBlackListMaintenance,
    miniMiranda,
    consumerStatusManintenance,
    kpiManitenance,
    bankruptcyMaitenance,
    strategicTemplateMaintenance,
  ];
  let miscArray = [
    requestInformation,
    invoiceItemMaintenance,
    letterStatus,
    contactStatusMain,
    locationTracker,
  ];

  let settingResult = settingArray?.includes(true);
  let miscResult = miscArray?.includes(true);

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `notificationCount ${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          statusType: "bell",
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await notificationStatus({
        org_id: currentOrganization,
        filters,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: true,
      refetchInterval: 120000,
    }
  );

  useEffect(() => {
    setTimeout(() => {
      handleMouseMove();
    }, 1000);
  }, [isClicked]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  function close() {
    setIsOpen(!isOpen);
  }

  function userManual() {
    if (
      currentDefaultFormatDetails.client_user_manual_url === HELP_CENTER ||
      currentDefaultFormatDetails.user_manual_url === HELP_CENTER
    ) {
      let destination = location.pathname.split("/");
      let checkingDestination;
      if (customerId) {
        checkingDestination = "consumer-overview-20";
      } else {
        checkingDestination = getUserManualPath(destination[1]);
      }
      openInNewTab(`${HELP_CENTER}/${checkingDestination}`);
    } else if (
      isClientLogin &&
      currentDefaultFormatDetails.client_user_manual_url !== HELP_CENTER
    ) {
      openInNewTab(`${currentDefaultFormatDetails.client_user_manual_url}`);
    } else if (
      !isClientLogin &&
      currentDefaultFormatDetails.user_manual_url !== HELP_CENTER
    ) {
      openInNewTab(`${currentDefaultFormatDetails.user_manual_url}`);
    }
  }
  const customReports = (referenceData["custom_reports"] || []).map(
    (report) => ({
      name: report.label,
      path: `/report/${report.id}`,
      permission: REPORT_MAINTAINANCE_ALL,
    })
  );
  let subMenus = {
    dashboard: [
      { name: "Dashboard", path: "/", permission: USER_DASHBOARD },
      {
        name: "Client Dashboard",
        path: "/business-dashboard",
        permission: CLIENT_DASHBOARD,
      },
      {
        name: "Consumer Action Analysis",
        path: "/CustomerAnalysis",
        permission: CUSTOMERANALYSIS,
      },
      {
        name: "SMS Analytics",
        path: "/sms-analytics",
        permission: SMS_ANALYTICS,
      },
      {
        name: "Revenue Dashboard",
        path: "/revenue-dashboard",
        permission: REVENUE_DASHBOARD,
      },
      {
        name: "Command Center Dashboard",
        path: "/command-center",
        permission: COMMAND_CENTER_DASHBOARD,
      },
      {
        name: "Debt Collection Dashboard",
        path: "/debt-collection-dashboard",
        permission: DEBT_COLLECTION_DASHBOARD,
      },
      {
        name: "Performance Dashboard",
        path: "/performance-dashboard",
        permission: PERFORMANCE_DASHBOARD,
      },
      {
        name: "Email Analytics",
        path: "/email-analytics",
        permission: EMAIL_ANALYTICS,
      },
    ],
    analytics: [
      {
        name: "Activity",
        path: "/report/activity",
        permission: ACTIVITY_REPORT,
      },
      {
        name: "Promise To Pay",
        path: "/report/promise-to-pay",
        permission: PROMISE_REPORT,
      },
      {
        name: "Dispute",
        path: "/report/dispute",
        permission: DISPUTE_REPORT,
      },
      {
        name: "Payment",
        path: "/report/payment",
        permission: PAYMENT_REPORT,
      },
      { name: "Notes", path: "/report/note", permission: NOTES_REPORT },
      {
        name: "Credit Limit",
        path: "/report/credit-limit",
        permission: CREDIT_LIMIT_REPORT,
      },
      {
        name: "Aging Summary",
        path: "/report/aging-summary",
        permission: AGING_SUMMARY_REPORT,
      },
      {
        name: "Breach Report",
        path: "/report/compliance-breach",
        permission: COMPLIANCE_BREACH_REPORT,
      },
      {
        name: "Needs Action",
        path: "/report/needs-action",
        permission: NEEDS_ACTION_REPORT,
      },
      {
        name: "Payment Plan",
        path: "/report/payment-plan",
        permission: PAYMENT_PLAN_REPORT,
      },
      {
        name: "Skip Trace",
        path: "/report/skip-trace",
        permission: SKIP_TRACE_REPORT,
      },
      {
        name: "Payment Plan Installment",
        path: "/report/payment-plan-installment",
        permission: PAYMENT_PLAN_INSTALMENT,
      },
      {
        name: "Adjustment Report",
        path: "/report/adjustment",
        permission: ADJUSTMENT_REPORT,
      },
      ...(currentDefaultFormatDetails.accounting_book === "CSV"
        ? [
            {
              name: "Checkin Report",
              path: "/report/checkin",
              permission: CHECKIN_REPORT,
            },
            {
              name: "Recurring Report",
              path: "/report/recurring",
              permission: RECURRING_REPORT,
            },
          ]
        : []),
      {
        name: "Legal Motion",
        path: "/report/legal-motion",
        permission: LEGAL_MOTION_REPORT,
      },
      {
        name: "Legal Timesheet",
        path: "/report/legal-timesheet",
        permission: LEGAL_TIMESHEET_REPORT,
      },
      {
        name: "Legal Summary",
        path: "/report/legal-summary",
        permission: LEGAL_SUMMARY_REPORT,
      },
      {
        name: "Legal Debt",
        path: "/report/legal-debt",
        permission: LEGAL_DEBT_REPORT,
      },
      ...(currentDefaultFormatDetails.accounting_book === "CSV"
        ? [
            {
              name: "Review Grouping",
              path: "/report/review-grouping",
              permission: REVIEW_GROUP_REPORT,
            },
          ]
        : []),
      {
        name: "Unsubscribe Report",
        path: "/report/un-subscribe",
        permission: COM_UN_SUBSCRIBE_REPORT,
      },
      {
        name: "Report Download",
        path: "/report-download",
        permission: ALL_REPORTS_DOWNLOAD_ACCESS,
      },
      ...customReports,
    ],
    settings: [
      {
        name: "Communication Template",
        path: "/templates",
        permission: COMMUNICATION_TEMPLATE_MAINTENANCE,
      },
      {
        name: "Workflow Maintenance",
        path: "/workflows",
        permission: WORKFLOW_MAINTENANCE,
      },
      {
        name: "Strategy Maintenance",
        path: "/strategy-maintenance",
        permission: STRATEGY_MAINTAINANCE,
      },

      {
        name: "Report Maintenance",
        path: "/report-maintenance",
        permission: REPORT_MAINTAINANCE_ALL,
      },

      {
        name: "Holiday Blackout Maintenance",
        path: "/holiday-blackout-maintenance",
        permission: HOLIDAY_BLACKLIST_MAINTENANCE,
      },
      {
        name: "Consumer Status Maintenance",
        path: "/consumer-status-maintenance",
        permission: VIEW_CONSUMER_STATUS,
      },
      {
        name: "Mini Miranda",
        path: "/mini-miranda-configuration",
        permission: MINI_MIRANDA_CONFIGURATION,
      },
      {
        name: "KPI Maintenance",
        path: "/kpi_maintenance",
        permission: KPI_MENU_CODE,
      },
      ...(currentDefaultFormatDetails.accounting_book === "CSV" ||
      currentDefaultFormatDetails.accounting_book === "ROTESSA"
        ? [
            {
              name: "Invoice Item",
              path: "/invoice-item",
              permission: INVOICE_ITEM_MAINTENANCE,
            },
          ]
        : []),
      {
        name: "Role Maintenance",
        path: "/role-maintenance",
        permission: ROLE_MAINTENANCE,
      },
      {
        name: "User Maintenance",
        path: "/users",
        permission: USER_MAINTENANCE,
      },
      {
        name: "Organization Settings",
        path: "/settings",
        permission: ORGANIZATION_SETTINGS,
      },
      // {
      //   name: "Report Delivery Settings",
      //   path: "/report-delivery-settings",
      //   permission: KPI_MENU_CODE,
      // },
    ],
    misc: [
      {
        name: "Client Maintenance",
        path: "/business-unit",
        permission: BUSINESS_UNIT_MAINTENANCE,
      },
      {
        name: "Revenue and Billing",
        path: "/revenue-billing",
        permission: REVENUE_BILLING,
      },
      {
        name: "Request Information",
        path: "/request-information",
        permission: REQUEST_INFORMATION,
      },
      {
        name: "Contact Status Maintenance",
        path: "/contact-status-maintenance",
        permission: Contact_Status_Maintenance,
      },
      {
        name: "Letter Status Maintenance",
        path: "/letterStatus",
        permission: LETTER_STATUS,
      },
      {
        name: "Bankruptcy Maintenance",
        path: "/bankruptcy-maintenance",
        permission: BANKRUPTCY_MAINTENANCE,
      },
      {
        name: "NACHA Maintenance",
        path: "/nacha-maintenance",
        permission: CHECK_MAINTENANCE,
      },
      {
        name: "Sticky Notes Maintenance",
        path: "/sticky-notes-maintenance",
        permission: STICKY_MAINTENANCE,
      },
      {
        name: "Location Tracking",
        path: "/location-tracking",
        permission: LOCATION_TRACKER,
      },
    ],
    help: [
      {
        name: "Contact us",
        ifFunction: setIsOpen,
      },
      {
        name: "User Manual",
        ifFunction: userManual,
      },
    ],
  };

  let mainMenuList = [
    {
      name: "Dashboard",
      lang: "dashboard",
      icon: "dashboard",
      path: "",
      permission: [DASHBOARD],
      is_sub_menu: true,
    },
    {
      name: "Consumers",
      lang: "consumers",
      icon: "consumers",
      path: "/customers",
      permission: [CUSTOMER_OVERVIEW],
    },
    {
      name: "Invoices",
      lang: "invoices",
      icon: "invoices",
      path: "/invoices",
      permission: [INVOICE_OVERVIEW],
    },
    ...(currentDefaultFormatDetails.accounting_book !== "CSV"
      ? [
          {
            name: "Credit Notes",
            lang: "credit_note",
            icon: "credit_note",
            path: "/credit-notes",
            permission: [CREDIT_NOTE],
          },
        ]
      : []),
    {
      name: "Adhoc Upload",
      lang: "csv_upload",
      icon: "csv_upload",
      path: "/uploads",
      permission: [ADHOC_UPLOAD],
    },
    {
      name: "Client Upload",
      lang: "client_upload",
      icon: "csv_upload",
      path: "/client-uploads",
      permission: [UPLOAD_CLIENT_CUSTOMER_INVOICE],
    },
    {
      name: "Reports",
      lang: "analytics",
      icon: "analytics",
      path: "",
      permission: [REPORTS],
      is_sub_menu: true,
    },
    ...(miscResult
      ? [
          {
            name: "Utilities",
            lang: "misc",
            icon: "misc",
            path: "",
            permission: [],
            is_sub_menu: true,
          },
        ]
      : []),
  ];

  const allowedRoutes = mainMenuList.filter((rt) => {
    if (!rt.permission || rt.permission.length === 0) {
      return true;
    }
    const hasAllPermission = rt.permission.map(
      (perm) => space && space.role_permissions?.includes(perm)
    );
    if (hasAllPermission.some((e) => e)) {
      return true;
    }
    return false;
  });

  //   const rootPathRoute = allowedRoutes.filter((e) => e.isRootPath);

  let secondaryMenuList = [
    {
      name: "Settings",
      lang: "settings",
      icon: "settings",
      is_sub_menu: true,
      isAccess: settingResult || false,
    },
    {
      name: "Notification",
      lang: "notification",
      icon: "notification",
      isFunction: () => setIsSideBarActive(true),
    },
    {
      name: "Profile",
      lang: "profile",
      icon: "profile",
      path: "/profile",
    },
  ];

  return (
    <div
      ref={divRef}
      className={`sidebar-wrapper-v2 main-menu ${
        isClicked ? "cusor_disable" : ""
      } ${isSideBarAvtive ? "search_active" : ""} ${
        isClientLogin ? "is_client_log" : ""
      }`}
      // onClick={() => {
      //   setIsSideBarActive(false);
      // }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {currentDefaultFormatDetails && currentDefaultFormatDetails.label}
        </title>
        <link
          rel="icon"
          href={
            currentDefaultFormatDetails && currentDefaultFormatDetails.org_logo
          }
        />
      </Helmet>
      <div
        className={`transition-wrapper ${isSideBarAvtive ? "expanded" : ""}`}
      >
        <div className="menus_container">
          <div className="sidebar-top-v2">
            <div className="siebar_top_section_container">
              <div
                onClick={() => {
                  // handleClick;
                  handleMouseEnter("ORG");
                }}
                // onMouseEnter={() => handleMouseEnter("ORG")}
                style={{ marginBottom: "10px", cursor: "pointer" }}
              >
                <div className="sidebar-switch__container">
                  <OrgSwitch />
                </div>
              </div>

              {/* <div className="icon-parent"> */}
              <RBACWrapper role={GLOBAL_SEARCH_PERMISSION} type="PERMISSION">
                <div
                  className="sidebar_icon_text_container"
                  // onMouseEnter={() => handleMouseEnter("SEARCH")}
                  onClick={() => {
                    setIsSideBarActive(true);
                    handleMouseEnter("SEARCH");
                  }}
                >
                  <SearchIcon fill={"#ffffff"} className="icn" />
                  <Typography type="h3" subType="medium">
                    Search
                  </Typography>
                </div>
              </RBACWrapper>

              <hr className="sidebar_hr" />

              {allowedRoutes.map((mm, i) => {
                return (
                  <div
                    className={`sidebar_icon_text_container ${
                      mm.sub_menu && "sub_menu_enable"
                    } `}
                    key={i}
                    // onMouseEnter={() => handleMouseEnter(mm.lang)}
                    // onMouseOut={() => {
                    //   handleMouseEnter("");
                    // }}
                    onClick={() => {
                      if (!mm.is_sub_menu) {
                        navigator(mm.path);
                      }
                      handleMouseEnter(mm.lang);
                    }}
                  >
                    <Icon icon={mm.icon} color={"#ffffff"} />
                    {mm.is_sub_menu && (
                      <Icon
                        icon={"triple_dot"}
                        color={"#ffffff"}
                        className="sub-menu-indicator"
                      />
                    )}

                    <Typography type="h3" subType="medium">
                      {mm.name}
                    </Typography>
                    {mm.is_sub_menu ? (
                      <Icon
                        icon="chevron_right"
                        color="#ffffff"
                        className="sub-menu-indicator_chevron_right"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
              <hr className="sidebar_hr" />
              {/* </div> */}
              {secondaryMenuList.map((mm, i) => {
                return (
                  <div
                    className={`sidebar_icon_text_container ${
                      mm.sub_menu && "sub_menu_enable"
                    } `}
                    key={i}
                    // onMouseEnter={() => {
                    //   if (!mm.isFunction) {
                    //     handleMouseEnter(mm.lang);
                    //   }
                    // }}
                    // onMouseOut={() => {
                    //   handleMouseEnter("");
                    // }}
                    onClick={() => {
                      if (!mm.is_sub_menu) {
                        navigator(mm.path);
                      }
                      if (mm.isFunction) {
                        mm.isFunction();
                        handleMouseEnter(mm.lang);
                      }
                      if (!mm.isFunction) {
                        handleMouseEnter(mm.lang);
                      }
                    }}
                  >
                    <Icon icon={mm.icon} color={"#ffffff"} />
                    {mm.is_sub_menu && (
                      <Icon
                        icon={"triple_dot"}
                        color={"#ffffff"}
                        className="sub-menu-indicator"
                      />
                    )}
                    {mm.lang === "notification" &&
                      data &&
                      data.data &&
                      data.data.is_active_notification && (
                        <Icon
                          icon={"dot"}
                          color={"#fd372a"}
                          size={10}
                          className="notification-indicator"
                        />
                      )}
                    <Typography type="h3" subType="medium">
                      {mm.name}
                    </Typography>
                    {mm.is_sub_menu ? (
                      <Icon
                        icon="chevron_right"
                        className="sub-menu-indicator_chevron_right"
                        color="#ffffff"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="sidebar_bottom_menu">
              <div
                className={`sidebar_icon_text_container`}
                onClick={() => {
                  handleModal({
                    title: "You are about to Logout?",
                    successCallback: () => {
                      resetIndexedDB({ dbKey: "referenceData" });
                      resetIndexedDB({ dbKey: "space" });
                      keycloak.logout({
                        redirectUri: "https://www.maxyfi.com",
                      });
                    },
                    formContent: () => {
                      return <></>;
                    },
                    cancelCallback: () => {},
                    isChildren: false,
                    image: warningImage,
                    buttonText: "Yes",
                    closeButtonText: "Cancel",
                    isCloseAble: false,
                  });
                }}
              >
                <Logout color={"#ffffff"} />
                <Typography type="h3" subType="medium">
                  Log Out
                </Typography>
              </div>

              <hr className="sidebar_hr --hr_mr" />
              <div
                className={`sidebar_icon_text_container`}
                // onMouseEnter={() => handleMouseEnter("help")}
                onClick={() => handleMouseEnter("help")}
              >
                <Icon icon={"help"} color={"#ffffff"} />
                <Typography type="h3" subType="medium">
                  Help
                </Typography>
              </div>
            </div>
          </div>
          {hoveredId === "help" ? (
            <div className="nav_sub_menu">
              {subMenus[hoveredId].map((e, i) => (
                // <RBACWrapper role={e.permission} type="PERMISSION">
                <Typography
                  key={i}
                  type="h3"
                  subType="regular"
                  onClick={e.ifFunction}
                >
                  {e.name}
                </Typography>
                // </RBACWrapper>
              ))}
            </div>
          ) : subMenus[hoveredId]?.length ? (
            <div className="nav_sub_menu">
              {subMenus[hoveredId].map((e, i) => (
                <RBACWrapper role={e.permission} type="PERMISSION">
                  <Typography
                    key={i}
                    type="h3"
                    subType="regular"
                    onClick={() => navigator(e.path)}
                  >
                    {e.name}
                  </Typography>
                </RBACWrapper>
              ))}
            </div>
          ) : hoveredId === "ORG" ? (
            <>
              <div className="nav_sub_menu">
                <OrgList />
              </div>
            </>
          ) : hoveredId === "SEARCH" ? (
            <>
              <div className="nav_sub_menu">
                <GlobalSearch_V2 />
              </div>
            </>
          ) : hoveredId === "notification" ? (
            <>
              <div className="nav_sub_menu">
                <NotificationDrawer_V2
                  tabCounts={data && data.data && data.data.docs}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ContactModal isOpen={isOpen} close={close} setIsOpen={setIsOpen} />
    </div>
  );
};

export default SideBar_V2;
